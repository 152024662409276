<!--  -->
<template>
  <div class="Introduction">
    <div class="head_NavBar">
      <van-icon name="arrow-left" size="20" @click="back()" />
      <div class="title">展会介绍</div>
    </div>
    <div class="video_box">
      <video
        ref="video"
        id="video"
        poster="../../images/ins/huizhan.png"
        :src="videoType.src"
        :autoplay="videoType.autoplay"
        :controls="videoType.controls"
        :controlslist="videoType.controlslist"
      ></video>
    </div>
    <div class="peitu1">
      <img src="../../images/ins/p1.png" />
    </div>
    <div class="nei_cont">
      <img class="t1" src="../../images/ins/t1.png" alt="" />
      <img class="t2" src="../../images/ins/t2.png" alt="" />
      <img class="t3" src="../../images/ins/t4.png" alt="" />
      <p class="title">
        2022天府书展<br />
        以“阅读新时代,奋进新征程”为年度主题
      </p>
      <p class="text">
        邀请中国出版集团有限公司作为永久主宾团，邀请上海参展团为年度主宾团，邀请国内具有重要影响力的出版集团、出版单位、馆配商参展。展示展销党的创新理论著作和党的二十大重要文件文献、“农民喜爱的百种图书”、振兴四川出版优秀出版物、全国优秀出版物和文创产品等内容，重点开展学习宣传贯彻落实党的二十大精神主题活动，“农民喜爱的百种图书”揭幕仪式、2022新时代乡村阅读盛典等国家部委举办的重大活动以及多个跨圈联动、全民参与的特色创新活动。
      </p>
      <p class="title">
        11月25日-28日<br />
        主分会场同步，线上线下融合，全民阅读联动，展示展销结合
      </p>
      <p class="text">
        线下以成都世纪城新会展中心1号馆为主展场，三苏祠博物馆为重点分展场，全省文轩实体书店、三州新华书店为主要分展场，全省公共图书馆为分会场。线上以"文轩九月"为主体设“天府书展·云世界”主展场，在文轩云店开设线下线上融合办展子展场,联合京东、天猫、“学习强国”商城、抖音设立线上分展场，联合省内外文轩网协同书店建立“天府书展·云世界”协同展场。
      </p>
    </div>
    <div class="danwei_nei">
      <img class="t4" src="../../images/ins/t3.png" alt="" />
      <div class="title">指导单位</div>
      <div class="text">
        中宣部印刷发行局 丨 中共四川省委宣传部 丨 成都市人民政府指导
      </div>
      <div class="title">承办单位</div>
      <div class="text">
        四川新华出版发行集团有限公司 丨 新华文轩出版传媒股份有限公司
      </div>
      <div class="title">协办单位</div>
      <div class="text">
        中国出版协会 丨 中国期刊协会 丨 中国书刊发行业协会 丨 中国版权协会 丨
        中国印刷技术协会 丨 各市（州）党委宣传部
      </div>
    </div>
    <!-- 导航 -->
    <navBer :navNum="2"></navBer>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { NavBar, Icon } from "vant";
export default {
  name: "Introduction",
  //import引入的组件需要注入到对象中才能使用
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
  },
  data() {
    //这里存放数据
    return {
      videoType: {
        src: "http://tfbookfair2022.symansbon.cn/images/video.mp4",
        autoplay: false, // 自动播放
        controls: "controls", //操作
        controlslist: "nodownload   noplaybackrate",
        /*
        1、nodownload: 不要下载按钮
        2、nofullscreen: 不要全屏按钮
        3、noremoteplayback: 不要远程回放
        4、disablePictureInPicture 不要画中画按钮
        5、noplaybackrate 不要播放速度按钮
        */
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    back() {
      this.$router.back();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="less">
.video_box {
  padding: 0 27px;
  margin-bottom: 15px;
  video {
    width: 100%;
    // height: 160px;
    object-fit: fill;
  }
}
.peitu1 {
  padding: 0 27px;
  margin-bottom: 40px;
  img {
    width: 100%;
    padding-bottom: 40px;
    border-bottom: 1px solid hsla(0, 0%, 44%, 0.25);
  }
}
.nei_cont {
  position: relative;
  padding: 0 27px;
  padding-bottom: 10px;
  border-bottom: 1px solid hsla(0, 0%, 44%, 0.25);
  .title {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    line-height: 1.8;
    margin-bottom: 13px;
  }
  .text {
    font-size: 14px;
    font-weight: 400;
    color: #555555;
    line-height: 1.75;
    text-indent: 2em;
    margin-bottom: 30px;
  }
}
.danwei_nei {
  padding: 40px 27px 50px 27px;
  background-image: url(../../images/ins/b3.png);
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
  position: relative;
  .title {
    font-size: 13px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    margin-bottom: 15px;
  }
  .text {
    font-size: 12px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #555555;
    margin-bottom: 32px;
    line-height: 1.75;
  }
}
.Introduction {
  padding-bottom: 75px;
  overflow: hidden;
}
.t1 {
  position: absolute;
  width: 75px;
  top: 65px;
  left: 0;
}
.t2 {
  position: absolute;
  width: 157px;
  right: -100px;
  top: 200px;
}
.t3 {
  position: absolute;
  width: 58px;
  right: 10px;
  top: 400px;
}
.t4 {
  position: absolute;
  width: 157px;
  top: 65px;
  left: -15px;
}
</style>